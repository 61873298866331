<template>
  <div>
    <modal name="open_to_add_collection_name" :adaptive="true" height="auto" width="500">
      <AddCollectionModal ref="addCollectionModal" @onSubmit="addCollectionModalSubmit"></AddCollectionModal>
    </modal>

    <!-- LOADING BOX -->
    <default-loading v-if="catalog_loading" :minHeight="'80vh'"></default-loading>
    <!-- DETAILS -->
    <div v-show="!catalog_loading" class="course-details-wrapper topic-1 uk-light pt-5">
      <div class="container p-sm-0">
        <div uk-grid>
          <div class="uk-width-2-3@m" style="margin-left: 27rem">
            <div class="course-details">
              <span>{{
                item.training_type ? item.training_type.name : ""
              }}</span>
              <h1>{{ item.name }}</h1>
              <div class="course-details-info">
                <ul>
                  <li>
                    <i class="icon-feather-users"></i>
                    {{ item.users_completed_count }}
                    {{ $t("education.Person_Completed_Training") }}
                  </li>
                </ul>
              </div>
            </div>
            <nav class="responsive-tab style-5">
              <ul
                uk-switcher="connect: #course-intro-tab ;animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium">
                <li>
                  <a href="#"><i class="uil-list-ul"></i>
                    {{ $t("education.descriptions") }}</a>
                </li>
                <li>
                  <a href="#"><i class="uil-comment-info-alt"></i>
                    {{ $t("education.Sharing_Experience") }}</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <!-- CONTENTS -->
    <div v-show="!catalog_loading" class="container">
      <div class="uk-grid-large mt-4" uk-grid>
        <div class="uk-width-2-3@m">
          <div class="uk-alert-success position-relative" uk-alert v-if="successMessage != null">
            <a class="uk-alert-close" uk-close></a>
            <p style="color: green" class="m-0">
              <i class="fa fa-check d-inline-flex mr-2" aria-hidden="true"></i>{{ successMessage }}
            </p>
          </div>

          <div class="uk-alert-danger bg-soft-danger position_relative" uk-alert v-if="errorMessage != null">
            <a class="uk-alert-close" uk-close></a>
            <p style="color: red" class="m-0">{{ errorMessage }}</p>
          </div>
          <ul id="course-intro-tab" class="uk-switcher mt-4">
            <!-- Contents -->
            <li>
              <h4>{{ $t("education.about") }}</h4>
              <p class="mb-4">{{ item.description }}</p>
              <div uk-margin class="mb-4">
                <a v-for="(item, index) in item.hashtag" href="javascript:void(0)" class="btn btn-small btn-light"
                  :key="index">#{{ item.name }}</a>
              </div>
              <div class="uk-alert-primary2 p-4" v-if="is_add_collection" uk-alert>
                <a class="uk-alert-close" uk-close></a>
                <h4>
                  <i class="uil-notebooks"></i>
                  {{ $t("education.Add_collection") }}
                </h4>
                <p>{{ $t("education.Add_collection_text") }}</p>
                <select class="uk-select mb-2" v-model="collection_id">
                  <option value="null">
                    {{ $t("education.Select_Collection") }}
                  </option>
                  <option value="0">
                    [+] {{ $t("education.New_Collection") }}
                  </option>
                  <template v-for="(item, index) in collections">
                    <option :value="item.id" :selected="item.id === collection_id">
                      {{ item.name }}
                    </option>
                  </template>
                </select>
                <button @click.prevent="onsubmit" :disabled="collection_content_ids == null && collection_id != null
                  " type="button" class="btn btn-secondary btn-icon-label mt-2">
                  <span class="btn-inner--icon">
                    <i class="uil-notebooks"></i>
                  </span>
                  <span class="btn-inner--text">{{ $t("general.save") }}</span>
                </button>
                <button type="button" class="btn btn-soft-secondary mt-2 ml-2">
                  {{ $t("general.give_up") }}
                </button>
              </div>

              <ul class="course-curriculum" uk-accordion="multiple: true">
                <li class="uk-open" v-if="is_add_collection">
                  <a class="uk-accordion-title" href="#">{{
                    $t("education.collection_subDiv")
                  }}</a>
                  <div class="uk-accordion-content">
                    <ul class="course-curriculum-list">
                      <template v-for="(section, index) in item.training_section">
                        <li class="watch" v-for="(
                            educationitems, education_index
                          ) in section.training_content" :key="'add_collection_' + index + '_' + education_index
                            ">
                          <input type="checkbox" class="d-inline-flex mb-0 mr-1" v-model="collection_content_ids"
                            :value="educationitems.id" :id="'add_collection_checkbox_' + educationitems.id" />
                          <a>
                            <label class="small m-0 cursor-pointer" :for="'add_collection_checkbox_' + educationitems.id
                              ">
                              {{ educationitems.name }}</label>
                          </a>
                        </li>
                      </template>
                    </ul>
                  </div>
                </li>

                <li v-for="(subItem, index) in item.training_section" :class="open_section_id == subItem.id ||
                  (open_section_id == null && index == 0)
                  ? 'uk-open'
                  : ''
                  " :key="index">
                  <a class="uk-accordion-title" href="#"> {{ subItem.name }}</a>
                  <div class="uk-accordion-content">
                    <ul class="course-curriculum-list">
                      <template v-for="(trainingContent, subIndex) in subItem.training_content">
                        <ContentItem :key="'item_' + subIndex"
                          @linkClick="showContentModal(trainingContent, trainingContent.completed_by_user)"
                          v-if="trainingContent.file_category" :icon="trainingContent.file_category.icon"
                          :is_completed="item.unlocked_items.includes(trainingContent.id) ? true : false"
                          :is_unlocked="item.has_been_assigned == true && item.current_item == trainingContent.id ? true : false"
                          :is_locked="(item.has_been_assigned != true || item.locked_items.includes(trainingContent.id)) && !item.user_self_register_training ? true : false"
                          :is_demo="trainingContent.is_demo" :name="$t(trainingContent.file_category.key_name)"
                          :title="trainingContent.name" :point="trainingContent.score"
                          :has_been_assigned="item.has_been_assigned"></ContentItem>
                      </template>
                    </ul>
                  </div>
                </li>
              </ul>
              <div id="training_content_modal" uk-modal esc-close="true" bg-close="true" class="p-0"
                style="z-index: 9999999; background: rgba(0, 0, 0, 1)">
                <div class="uk-modal-dialog bg-transparent" v-if="selected_content != null" style="width: 100%">
                  <button class="uk-modal-close-default text-white mt-1 mr-3" type="button" uk-close></button>
                  <div class="uk-modal-header bg-transparent border-0 px-5">
                    <h2 class="uk-modal-title text-white">
                      {{ selected_content.name }}
                    </h2>
                  </div>
                  <div class="uk-modal-body py-0" uk-overflow-auto>
                    <div class="video-responsive" v-if="selected_content.training_file_type_id == 2">
                      <img :src="selected_content.file.file_link" />
                    </div>
                    <div class="video-responsive" v-if="selected_content.training_file_type_id == 6">
                      <audio controls="" :src="selected_content.file.file_link"></audio>
                    </div>
                    <div class="video-responsive" v-if="selected_content.training_file_type_id == 7">
                      <template>
                        <div class="video-time"><span class="now">{{ video_now }}</span> / <span class="total">{{
                          video_total }}</span></div>
                        <div class="artplayer-app"></div>
                      </template>
                    </div>
                    <div class="iframe-responsive" v-if="selected_content.training_file_type_id == 8">
                      <iframe width="100vw" height="100vh" :src="selected_content.training_content"
                        :title="selected_content.title" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen>
                      </iframe>
                    </div>
                    <div class="iframe-responsive" v-if="selected_content.training_file_type_id == 3">
                      <iframe width="100vw" height="100vh" :src="selected_content.file.file_link +
                        '#toolbar=0&navpanes=0&scrollbar=0'
                        " :title="selected_content.title" frameborder="0">
                      </iframe>
                    </div>
                    <div class="iframe-responsive" v-if="[9].includes(selected_content.training_file_type_id)
                      ">
                      <iframe width="100vw" height="100vh" :src="selected_content.link" :title="selected_content.title"
                        frameborder="0">
                      </iframe>
                    </div>
                    <div class="iframe-responsive" v-if="selected_content.training_file_type_id == 10">
                      <iframe width="100vw" height="100vh" :src="anzeraPackageItemUrl" :title="selected_content.title"
                        frameborder="0">
                      </iframe>
                    </div>
                    <div v-if="selected_content.training_file_type_id == 1" class="iframe-responsive">
                      <iframe :src="scormPackageItemUrl" :title="selected_content.title" frameborder="0" height="100vh"
                        width="100vw">
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <!-- Feedbacks -->
            <li class="course-description-content">
              <div class="comments mt-4">
                <h4>
                  {{ this.$t('general.experience_sharing') }}
                  <span class="comments-amount">({{ item.approved_comments ? item.approved_comments.length : 0 }}
                    {{ $t("education.Sharing") }})</span>
                </h4>
                <ul>
                  <template v-for="(comment, index) in item.approved_comments">
                    <Comments :id="'comment_' + comment.id" :key="'comment_index_' + index"
                      :image="comment.user.photo_link" :title="comment.user.position?.name"
                      :name="comment.user.name + ' ' + comment.user.surname" :comment="comment.text"
                      :status_id="comment.status_id">
                    </Comments>
                  </template>
                </ul>
              </div>

              <div class="comments">
                <ul>
                  <li>
                    <div class="comments-avatar">
                      <img :src="this.authUser.photo" />
                    </div>
                    <div class="comment-content">
                      <div class="uk-grid-small" uk-grid>
                        <div class="uk-width-1-1@s">
                          <textarea class="uk-textarea pt-2" placeholder="Buraya deneyimlerini yaz..."
                            v-model="comment_text" style="height: 160px"></textarea>
                        </div>
                        <div class="uk-grid-margin">
                          <button type="button" @click="makeComment" value="Gönder" class="btn btn-default">
                            {{ $t("messages.send") }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        <div class="uk-width-1-3@m uk-flex-first">
          <div class="course-card-trailer" uk-sticky="top: 10 ; media: @m ; bottom:true">
            <div class="course-thumbnail">
              <img :src="item.cover_image_link" alt="" />
            </div>

            <div class="p-3">
              <p style="font-size: 1rem" v-if="item.has_been_assigned">
                %{{ checkCompletion(item.completion_percentage) }}
                {{ $t("education.Completed") }}
              </p>
              <div class="course-progressbar mb-4" v-if="item.has_been_assigned">
                <div class="course-progressbar-filler continue" :class="[
                  item.completion_percentage >= 75 ? 'bg-success' : '',
                  item.completion_percentage < 75 &&
                    item.completion_percentage >= 50
                    ? 'bg-warning'
                    : '',
                ]" :style="'width:' + checkCompletion(item.completion_percentage) + '%'" :uk-tooltip="'title:%' +
  checkCompletion(item.completion_percentage) +
  ' ' +
  $t('education.Completed') +
  '; pos: top-center'
  "></div>
              </div>
              <div class="uk-child-width-2-2 uk-grid-small mb-4" uk-grid>
                <template v-if="item.has_been_assigned">
                  <div>
                    <a v-if="!item.is_started && !item.is_completed"
                      class="uk-width-1-1 btn text-white btn-danger transition-3d-hover" href="#"
                      @click.prevent="startFirstEducation">
                      <i class="uil-forward"></i>
                      <span>{{ $t("education.Start_Education") }}</span>
                    </a>
                    <a v-else-if="item.is_started && !item.is_completed" href="javascript:void(0)"
                      @click.prevent="startFromUnlockedEducation" class="
                        uk-width-1-1
                        btn
                        text-white
                        btn-danger
                        transition-3d-hover
                      ">
                      <i class="uil-forward"></i>
                      {{ $t("education.Continue_Where_I_Stay") }}
                    </a>
                    <a v-else-if="item.is_started && item.is_completed" href="javascript:void(0)" class="
                        uk-width-1-1
                        btn
                        text-white
                        btn-success
                        transition-3d-hover
                      ">
                      <i class="uil-forward"></i>
                      {{ $t("general.completed") }}
                    </a>
                  </div>
                  <div>
                    <a @click="addCollection" class="
                        uk-width-1-1
                        text-white
                        btn btn-primary
                        transition-3d-hover
                      ">
                      <i class="uil-brightness-plus"></i>
                      {{ $t("education.Add_collection") }}
                    </a>
                  </div>
                </template>
                <template v-else>
                  <template
                    v-if="!item.self_registrable_trainings?.length || item.self_registrable_trainings?.length === 0">
                    <button type="button" @click="requestForTraining(item.id)" class="btn mt-3 btn-sm btn-light"
                      v-if="item.user_request == null">
                      <i class="uil-bell"></i> Talep Et
                    </button>
                    <button type="button" class="btn mt-3 btn-sm btn-primary disabled" v-if="item.user_request != null &&
                      item.user_request.training_status_id == 1
                      ">
                      <i class="fa fa-check"></i> Talep Edildi
                    </button>
                    <button type="button" class="btn mt-3 btn-sm btn-danger" v-if="item.user_request != null &&
                      item.user_request.training_status_id == 0
                      ">
                      Talep Reddedildi
                    </button>
                  </template>
                  <template v-if="item.self_registrable_trainings?.length > 0">
                    <button type="button" @click="requestForSelfTraining(item.id)" class="btn mt-3 btn-sm btn-success"
                      v-if="!item.user_self_register_training">
                      <i class="uil-check-circle"></i> Kaydol
                    </button>
                    <button type="button" class="btn mt-3 btn-sm btn-warning disabled" v-if="item.user_self_register_training.registered_at || selfRegistrabled
                      ">
                      <i class="fa fa-check"></i> Kayıt olundu
                    </button>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Section from "@/view/components/educationDetail/Section";
import Tags from "@/view/components/search/Tags";
import Comments from "@/view/components/educationDetail/Comments";
import ContentItem from "@/view/components/assignmentDetail/ContentItem";
import { mapGetters } from 'vuex'
import store from "@/core/services/index";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import Artplayer from "artplayer";

import {
  GET_ITEM_DETAIL_BY_ID,
  CURRENT_ITEM,
  UPDATE_ITEM_BY_ID,
  GET_ITEMS,
  CREATE_ITEM,
  ERROR as REST_ERROR,
  LOADING as REST_LOADING
} from "@/core/services/store/REST.module";
import AddCollectionModal from "@/view/components/educationDetail/AddCollectionModal";

export default {
  name: "EducationDetails",
  components: {
    Section,
    Tags,
    Comments,
    AddCollectionModal,
    ContentItem,
    DefaultLoading
  },
  data() {
    return {
      restUrl: "api/training/",
      collectionUrl: "api/collection",
      videoContentUrl: "api/training/",
      apiURL: "api/user/self",
      scormUrl: "api/training-content/get-scorm/",
      commentUrl: "api/training/comment/",
      anzeraUrl: "api/training/anzera/",
      completeContentUrl: "api/training/complete-content/",
      trainingRequestUrl: "api/training/request/",
      collectionContentUrl: "api/training/collection-content/",
      startContentUrl: "api/training/start-content/",
      updateContentUrl: "api/training/update-content/",
      is_add_collection: false,
      collections: [],
      collection_id: null,
      collectionName: null,
      isScorm: false,
      scormPackageItemUrl: null,
      is_add_new_collection: false,
      collection_content_ids: [],
      unlocked_education: null,
      anzeraPackageItemUrl: "",
      collection_video: null,
      collection_type: [],
      collection_sound: null,
      successMessage: null,
      errorMessage: null,
      additional: [],
      item: [],
      comment_text: '',
      open_section_id: null,
      selected_content: null,
      trainingId: null,
      currentDuration: false,
      contentUpdating: false,
      updateContentData: {},
      tempPos: null,
      demo_status: true,
      video_now: "00:00",
      video_total: "00:00",
      selfRegistrable: false,
      selfRegistrabled: false
    };
  },
  computed: {
    ...mapGetters({
      catalog_loading: REST_LOADING
    }),

    restError: {
      get() {
        return this.$store.getters[REST_ERROR];
      },
      set(value) { },
    },
  },

  methods: {
    requestForSelfTraining(item_id) {
      this.$store
        .dispatch(UPDATE_ITEM_BY_ID, {
          url: 'api/trainings/' + item_id + '/self-registrable',
          // noLoading: true,
        })
        .then((result) => {
          self.contentUpdating = false;
          this.selfRegistrabled = true
          this.selfRegistrable = true;
        });
    },

    checkCompletion(percentage) {
      let completionPercentage = 0
      completionPercentage = percentage >= 100 ? 100 : percentage
      return completionPercentage
    },
    setUnlockedEducation() {
      if (this.item.has_been_assigned == false) return;
      this.unlocked_education = null;
      this.open_section_id = null;
      this.item.training_section.forEach((section) => {
        section.training_content.forEach((content) => {
          if (this.unlocked_education != null) return;
          if (!content.completed_by_user && content.is_demo == false) {
            this.open_section_id = section.id;
            this.unlocked_education = content.id;
            return false;
          }
        });
      });
    },
    getVideoWhereIStay(content_id) {
      let self = this;
      let url = self.videoContentUrl + self.$route.params.id + "/training-content/" + content_id + "/stay";

      self.$store
        .dispatch(GET_ITEM_DETAIL_BY_ID, {
          url: url,
          acceptPromise: false,
        })
        .then((result) => {
          if (result.status) {
            this.currentDuration = result.data;
          }
        });
    },
    updateContent() {
      let self = this;
      if (self.item.is_expired === true) return false;
      self.contentUpdating = true;

      let formData = this.updateContentData;

      if (!formData || !formData.id) return false;
      this.$store
        .dispatch(UPDATE_ITEM_BY_ID, {
          url: self.updateContentUrl + self.$route.params.id + "/" + formData.id,
          contents: formData,
          noLoading: true,
        })
        .then((result) => {
          self.contentUpdating = false;
        });
    },
    startFirstEducation() {
      let item = this.item;
      this.showContentModal(item.training_section[0].training_content[0], false);
      return true;
    },
    showContentModal(selected_content, is_completed) {
      this.isScorm = false;
      this.selected_content = selected_content;

      this.demo_status = !this.item.has_been_assigned && selected_content.is_demo
      if (this.demo_status) {
        UIkit.modal("#training_content_modal").show();
        return false;
      }
      this.setStartedAt();
      this.startContent(selected_content);

      if ([4, 5].includes(selected_content.training_file_type_id)) {
        this.completeContent(selected_content);
        this.downloadURI(selected_content.file.file_link);
        this.getTrainingItem()
        return true;
      }
      if (selected_content.training_file_type_id === 7) {
        this.getVideoWhereIStay(selected_content.id);
      }

      if (selected_content.training_file_type_id === 1) {
        this.isScorm = true;
        this.getScormEducationUrl(selected_content.id);
      }

      if (selected_content.training_file_type_id == 10) {
        this.getAnzeraEducationUrl(selected_content.id);
      }

      UIkit.modal("#training_content_modal").show();
    },
    startFromUnlockedEducation() {
      let sections = this.item.training_section;
      let pos = this.getSectionContentIndex(this.unlocked_education);
      let data = null
      if (pos != null) {
        this.tempPos = pos;
        data = sections[pos.section_index].training_content[pos.content_index];
      } else {
        if (this.tempPos)
          data = sections[this.tempPos.section_index].training_content[this.tempPos.content_index];
        data = sections[0].training_content[0];

      }
      this.showContentModal(data, false);
      return true;
    },

    makeComment() {
      if (this.comment_text.length < 2) {
        this.errorMessage = this.$t("validation.required_field").replace(
          ":NAME",
          this.$t("assignment.comment")
        );
        return false;
      }
      let formData = new FormData();
      formData.append("comment", this.comment_text);
      this.$store
        .dispatch(UPDATE_ITEM_BY_ID, {
          url: this.commentUrl + this.$route.params.id,
          contents: formData,
        })
        .then((result) => {
          if (!this.restError) {
            this.comment_text = "";
            if (Object.keys(result.data).length)
              this.item.approved_comments.unshift(result.data);
            this.successMessage = this.$t("general.successfully_created");
          } else this.errorMessage = this.restError;
        });
    },
    setStartedAt() {
      let self = this;
      this.$store.dispatch(UPDATE_ITEM_BY_ID, {
        url: self.restUrl + "started/" + self.$route.params.id,
        contents: {
          type: "started",
        },
      });
    },
    setCompletedAt() {
      let self = this;
      this.$store.dispatch(UPDATE_ITEM_BY_ID, {
        url: self.restUrl + "completed/" + self.$route.params.id,
        contents: {
          type: "completed",
        },
      });
    },
    getScormEducationUrl(content_id) {
      let self = this;
      let url = self.scormUrl + self.$route.params.id + "/training-content/" + content_id;
      self.$store
        .dispatch(GET_ITEM_DETAIL_BY_ID, {
          url: url,
          acceptPromise: false,
        })
        .then((result) => {
          if (result.status) {
            this.scormPackageItemUrl = result.data;
            return;
          }
        });
    },
    downloadURI(uri) {
      var link = document.createElement("a");
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.successMessage = this.$t("general.content_download_successfully");
    },
    getAnzeraEducationUrl(content_id) {
      let self = this;
      let url =
        self.anzeraUrl + self.$route.params.id + "/content/" + content_id;
      self.$store
        .dispatch(GET_ITEM_DETAIL_BY_ID, {
          url: url,
          acceptPromise: false,
        })
        .then((result) => {
          if (result.status) {
            this.anzeraPackageItemUrl = result.data;
            return;
          }
        });
    },
    getSectionContentIndex(edu_id) {
      let result = null;
      this.item.training_section.forEach((section, index) => {
        var content_index = section.training_content.findIndex(
          (content) => content.id == edu_id
        );
        if (content_index != -1) {
          result = { content_index: content_index, section_index: index };
          return result;
        }
      });
      return result;
    },
    setModalListener() {
      let self = this;
      $("#training_content_modal").on({
        //  'show.uk.modal': function(){
        //  },
        "hide.uk.modal": function () {

          if (self.demo_status) {
            self.selected_content = null;
            this.anzeraPackageItemUrl = "";
            return false;
          }
          if (+self.selected_content.training_file_type_id == 1) {
            self.getTrainingItem();
          }

          /* if ( !self.item.is_completed && self.selected_content.training_file_type_id == 10 ){
            self.completeContent(self.selected_content);
          } */

          if (+self.selected_content.training_file_type_id === 7) {
            self.updateContent();
            self.getTrainingItem();
          }

          if (![7].includes(self.selected_content.training_file_type_id)) {
            self.completeContent(self.selected_content);
          }
          if (self.selected_content.training_file_type_id) {
            self.getTrainingItem();
          }

          self.selected_content = null;
          this.anzeraPackageItemUrl = "";
        },
      });
    },
    completeContent(education) {
      let self = this;
      let formData = new FormData();
      formData.append("content_id", education.id);
      this.$store
        .dispatch(UPDATE_ITEM_BY_ID, {
          url:
            self.completeContentUrl +
            self.$route.params.id +
            "/" +
            education.id,
          contents: formData,
        })
        .then((result) => {
          if (!self.restError && result.status) {
            let data = result.data;
            let content_section_index = self.getSectionContentIndex(
              education.id
            );
            self.item.training_section[
              content_section_index.section_index
            ].training_content[
              content_section_index.content_index
            ].completed_by_user = 1;
            self.item.completion_percentage = data.completion_percentage;
            self.item.is_completed = data.is_completed;

            self.item.user_content_completed = data.user_content_completed;
            self.item.user_content_completed_count = data.user_content_completed_count;
            self.item.locked_items = data.locked_items;
            self.item.unlocked_items = data.unlocked_items;
            self.item.current_item = data.current_item;

            if (self.item.is_completed == true) {
              self.setCompletedAt();
            }

          } else self.errorMessage = self.restError;
        });
    },
    startContent(education) {
      let self = this;
      if (self.item.is_expired === true) return false;
      let formData = new FormData();

      formData.append("content_id", education.id);
      this.$store
        .dispatch(UPDATE_ITEM_BY_ID, {
          url: self.startContentUrl + self.$route.params.id + "/" + education.id,
          contents: formData,
        })
        .then((result) => {
          if (!self.restError && result.status) {
            let data = result.data;
            self.item.completion_percentage = data.completion_percentage;
            // self.item.user_content_completed = data.user_content_completed;
            // self.item.user_content_completed_count = data.user_content_completed_count;
            // self.item.is_completed = data.is_completed;

            // self.unlocked_education = self.getActiveEducationId(
            //     self.item.contents_programs,
            //     data.user_content_completed
            // ); 
          } else {
            self.errorMessage = self.restError;
          }
        });
    },
    addCollectionModalSubmit(payload) {
      if (!this.CollectionVisible) {
        return false;
      }
      let self = this;
      let tempId = null;

      this.$refs["addCollectionModal"].closeModal();

      if (payload.success) {
        self.successMessage = self.$t("education.collection_name_success");

        let temp = [...self.collections];
        temp.push({
          id: payload.data.id,
          name: payload.data.name,
        });
        tempId = payload.data.id;
        self.collections = [...temp];
        this.collection_id = tempId;
      } else {
        self.errorMessage = payload.message;
      }
    },
    requestForTraining(id) {
      this.$store
        .dispatch(CREATE_ITEM, {
          url: this.trainingRequestUrl + id,
          acceptPromise: true,
        })
        .then((result) => {
          if (result.status) {
            this.item.user_request = result.data;
          }
        });
    },
    onsubmit() {
      if (!this.collection_id) {
        this.errorMessage = this.$t("education.please_add_collection_name");
        return false;
      }
      if (!this.collection_content_ids.length) {
        this.errorMessage = "Lütfen içeriği seçin.";
        return false;
      }
      this.$store
        .dispatch(CREATE_ITEM, {
          url: this.collectionContentUrl + this.$route.params.id,
          contents: {
            content_ids: this.collection_content_ids,
            collection_id: this.collection_id,
          },
        })
        .then(() => {
          if (!this.errors) {
            this.successMessage = this.$t("education.collection_success");
          }
          this.is_add_collection = false;
        });
    },
    addCollection() {
      this.is_add_collection = true;
    },
    saveMessage() {
      this.$store.dispatch(SAVE_MESSAGE);
    },
    resetMessages() {
      this.errorMessage = null;
      this.successMessage = null;
    },
    getTrainingItem() {
      if (this.trainingId) {
        let self = this;
        this.$store
          .dispatch(GET_ITEM_DETAIL_BY_ID, {
            url: this.restUrl + this.trainingId,
            acceptPromise: false,
            showLoading: true,
          })
          .then((result) => {
            if (result.status) {
              self.item = result.data;
            }
          });
      } else {
        this.$router.go(-1);
      }
    },
    time_format(time) {
      let minute = Math.floor(time / 60)
      minute = minute.toString().padStart(2, '0')
      time = Math.floor(time) % 60
      time = time.toString().padStart(2, '0')
      return minute + ':' + time
    }
  },
  mounted() {
    let self = this;
    self.trainingId = this.$route.params.id;

    this.$store
      .dispatch(GET_ITEMS, {
        url: self.collectionUrl,
        acceptPromise: true,
      })
      .then((result) => {
        if (result.status) {
          self.collections = result.data.my_collections;
        }
      });

    self.getTrainingItem();
    self.setModalListener();

  },
  beforeDestroy() {
    const modal = UIkit.modal("#training_content_modal");
    if (modal) modal.$destroy(true);
  },
  watch: {
    item: {
      handler(val) {
        if (val && this.item.has_been_assigned) this.setUnlockedEducation();
      },
      deep: true,
    },
    collection_id(newValue) {
      if (newValue == 0) {
        this.is_add_collection = true;
        this.$modal.show("open_to_add_collection_name");
        this.collection_id = newValue.id;
      }
    },
    successMessage() {
      this.debouncedResetValues();
    },
    errorMessage() {
      this.debouncedResetValues();
    },
    selected_content(content) {
      if (content) {
        if (+content.training_file_type_id === 7) {
          let self = this;
          setTimeout(() => {
            let art = new Artplayer({
              container: '.artplayer-app',
              url: content.file.file_link ? content.file.file_link : content.file,
              poster: content.cover_photo_link ?? content.file.file_link,
              title: content.name,
              screenshot: true,
              moreVideoAttr: {
                crossOrigin: 'anonymous',
              },
              isLive: true,
              autoSize: true,
              autoMini: true,
              playbackRate: true,
              hotkey: false,
              fullscreen: true,
              fullscreenWeb: true,
              miniProgressBar: true,
              icons: {
                state: '<i class="uil-play"></i>',
              },
              lang: 'tr',
            });

            art.on('ready', () => {
              self.video_now = self.time_format(art.currentTime)
              self.video_total = self.time_format(art.duration)
              setTimeout(() => {
                art.currentTime = Number(self.currentDuration);
                if (Math.round(+self.currentDuration) === Math.round(+art.duration)) {
                  art.currentTime = 0
                }
              }, 1000);
            });
            art.on('video:timeupdate', (...args) => {
              setTimeout(() => {
                let duration = (art.currentTime * 100) / art.duration;
                self.video_now = self.time_format(art.currentTime)
                self.video_total = self.time_format(art.duration)
                self.updateContentData = {
                  id: content.id,
                  completion_percentage: Math.round(duration),
                  duration: art.currentTime,
                };
              }, 1000);
            });
            art.on('video:pause', (...args) => {
              setTimeout(() => {
                const duration = (art.currentTime * 100) / art.duration;
                self.updateContentData = {
                  id: content.id,
                  completion_percentage: Math.round(duration),
                  duration: art.currentTime,
                };
                if (self.demo_status) {
                  self.updateContent();
                }
              }, 1000);
            });

            art.on('video:ended', () => {
              setTimeout(() => {
                let duration = art.duration / 60;
                self.completeContent(content, duration);
              }, 3000);
            });
          }, 2000);
        }
      }
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 3000);
  },

};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.artplayer-app {
  width: 100vh;
  height: 100vh;
}
</style>

<style>
.art-video-player.art-contextmenu-show .art-contextmenus {
  display: none;
}

.video-time {
  position: absolute;
  top: 5%;
  left: 1%;
  font-size: 20px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 6px 10px;
}
</style>
